const IconMail = () => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.33268 3.33398H16.666C17.5827 3.33398 18.3327 4.08398 18.3327 5.00065V15.0006C18.3327 15.9173 17.5827 16.6673 16.666 16.6673H3.33268C2.41602 16.6673 1.66602 15.9173 1.66602 15.0006V5.00065C1.66602 4.08398 2.41602 3.33398 3.33268 3.33398Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.3327 5L9.99935 10.8333L1.66602 5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default IconMail;
